<style type="text/css">
    .row_detail {
        line-height: 32px;
    }

    .one {
        background: #e5e9f2;
    }


</style>
<template>

    <div style="margin:10px 10px 0 10px;background-color: #EFF1F4;height: 100vh">

        <!--搜索-->
        <div class="el-card is-always-shadow" style="padding:20px 0 0 20px;margin-top: 10px;border-radius: 0">
            <el-form ref="searchForm" :model="searchForm" size="mini" inline>
                <el-form-item label="社区" prop="name" v-if="hrole<11">
                    <el-select v-model="searchForm.hospital" filterable placeholder="请选择" clearable>
                        <el-option
                                v-for="item in HospitalAllData"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备名称" prop="name">
                    <el-select v-model="searchForm.device_id" filterable placeholder="请选择" @change="deviceChange">
                        <el-option v-for="item in deviceList" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据分类" prop="name" style="width: 220px">
                    <el-select v-model="searchForm.type" filterable placeholder="请选择" clearable
                               style="width: 140px">
                        <el-option v-for="item in deviceData" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="name">
                    <el-input v-model="searchForm.phone" maxlength="11" clearable/>
                </el-form-item>
                <el-form-item label="添加时间" prop="name">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="searchForm.create_time" type="daterange"
                                    style="width: 220px"
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-button type="primary" size="mini" @click="onSearch" icon="el-icon-search">查询
                </el-button>
                <el-button type="primary" size="mini" @click="downLoad" icon="el-icon-download">导出查询结果
                </el-button>
            </el-form>
        </div>
        <div style="margin-top: 10px;height:calc(100vh - 165px);background-color: #ffffff">
            <!--数据-->
            <div style="background-color: #ffffff;padding: 10px">
                <el-table :row-style="{height:0+'px'}" cell-style="padding:5px 0" :data="tableData" border
                          style="width: 100%;line-height: 40px" v-loading="loading"
                          header-cell-style="background-color: #f5f5f5; padding:0 0;">
                    <el-table-column prop="id" label="序号" width="96" align="center"></el-table-column>
                    <el-table-column v-if="hrole<11" prop="hospital_name" label="社区" align="center"
                                     min-width="150"></el-table-column>

                    <!--                    <el-table-column prop="mac" label="设备" min-width="85px" align="center"></el-table-column>-->
                    <el-table-column prop="name" label="姓名" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.name:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user.mobile" label="手机" align="center"></el-table-column>
                    <el-table-column prop="user.mobile" label="性别" width="50" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.sex==1?'男':'女':'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user.mobile" label="出生日期" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.birth:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user.mobile" label="身高" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.height:'/'}}cm
                        </template>
                    </el-table-column>
                    <el-table-column prop="user.mobile" label="体重" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.weight:'/'}}kg
                        </template>
                    </el-table-column>
                    <el-table-column prop="user.mobile" label="病史" width="198" align="center">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" width="350" trigger="hover" :content="scope.row.info">
                                <el-button v-if="scope.row.info" style="border: none;width: 98%" slot="reference">
                                    {{scope.row.info.substring(0, 10)}}
                                </el-button>
                                <el-button v-if="!scope.row.info" style="border: none;width: 98%" slot="reference">
                                    /
                                </el-button>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" label="数据分类" align="center"></el-table-column>
                    <el-table-column prop="name" label="测量数据" align="left">
                        <template slot-scope="scope">
                            <div style="font-size: 12px">
                                <span v-if="scope.row.pre1">{{scope.row.pre1}} <br/></span>
                                <span v-if="scope.row.pre2">{{scope.row.pre2}} <br/></span>
                                <span v-if="scope.row.pre3">{{scope.row.pre3}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DT" label="测量时间" align="center" min-width="86"></el-table-column>
                    <el-table-column prop="date" label="操作" width="138" align="center">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.type=='体脂称'" type="text" size="small"
                                       @click="showDetail(scope.row)">详情
                            </el-button>
                            <!--                            <el-button type="text" size="small" @click="editUser(scope.row)">编辑</el-button>-->
                            <el-button type="text" size="small" @click="delUser(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!--分页-->
            <div style="margin-top: 25px;text-align: center">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="page"
                               :page-sizes="[10, 20, 50]" :page-size="pagesize"
                               :total="total" @size-change="handleSizeChange"
                               @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>

        <!--体脂称详情-->
        <el-dialog title="体脂称指标详情" :visible.sync="detailDialog" width="750px">
            <el-row style="margin-bottom: 10px;font-size: 12px">
                <el-col :span="4">姓名：{{weight.user?weight.user.name:''}}</el-col>
                <el-col :span="5">手机号：{{weight.user?weight.user.mobile:''}}</el-col>
                <el-col :span="8">测量时间：{{weight.DT}}</el-col>
            </el-row>
            <el-row style="font-weight: bold;line-height: 38px">
                <el-col :span="2" align="center">序号</el-col>
                <el-col :span="3">指标名称</el-col>
                <el-col :span="3">测量结果</el-col>
                <el-col :span="3">是否正常</el-col>
                <el-col :span="1">|</el-col>
                <el-col :span="2">序号</el-col>
                <el-col :span="3">指标名称</el-col>
                <el-col :span="4">测量结果</el-col>
                <el-col :span="3">是否正常</el-col>
            </el-row>
            <el-row class="row_detail one">
                <el-col :span="2" align="center">01</el-col>
                <el-col :span="3">体重</el-col>
                <el-col :span="3">{{weightDetail.weight}}kg</el-col>
                <el-col :span="3">{{weightDetail.left_weight<33.3?"↓":weightDetail.left_weight>66.6?"↑":'✓'}}</el-col>
                <el-col :span="1">|</el-col>
                <el-col :span="2">02</el-col>
                <el-col :span="3">BMI</el-col>
                <el-col :span="4">{{weightDetail.bmi}}</el-col>
                <el-col :span="3">{{weightDetail.left_bmi<25?"↓":weightDetail.left_bmi>50?"↑":'✓'}}</el-col>
            </el-row>

            <el-row class="row_detail two">
                <el-col :span="2" align="center">03</el-col>
                <el-col :span="3">脂肪量</el-col>
                <el-col :span="3">{{weightDetail.fat}}kg&nbsp;</el-col>
                <el-col :span="3">
                    {{!weightDetail.fat?'-':weightDetail.left_fat<25?"↓":weightDetail.left_fat>50?"↑":'✓'}}
                </el-col>
                <el-col :span="1">|</el-col>
                <el-col :span="2">04</el-col>
                <el-col :span="3">骨骼肌量</el-col>
                <el-col :span="4">{{weightDetail.muscle}}kg&nbsp;</el-col>
                <el-col :span="3">
                    {{!weightDetail.muscle?'-':weightDetail.left_muscle<33.3?"↓":weightDetail.left_muscle>66.6?"↑":'✓'}}
                </el-col>
            </el-row>
            <el-row class="row_detail one">
                <el-col :span="2" align="center">05</el-col>
                <el-col :span="3">体水分率</el-col>
                <el-col :span="3">{{weightDetail.water}}%&nbsp;</el-col>
                <el-col :span="3">
                    {{!weightDetail.water?'-':weightDetail.left_water<33.3?"↓":weightDetail.left_water>66.6?"↑":'✓'}}
                </el-col>
                <el-col :span="1">|</el-col>
                <el-col :span="2">006</el-col>
                <el-col :span="3">内脏脂肪等级</el-col>
                <el-col :span="4">{{weightDetail.visceral}}级&nbsp;</el-col>
                <el-col :span="3">
                    {{!weightDetail.visceral?'-':weightDetail.left_visceral<33.3?"✓":weightDetail.left_visceral>66.6?"↑":'↑'}}
                </el-col>
            </el-row>
            <el-row class="row_detail two">
                <el-col :span="2" align="center">07</el-col>
                <el-col :span="3">骨盐量</el-col>
                <el-col :span="3">{{weightDetail.bone}}kg&nbsp;</el-col>
                <el-col :span="3">
                    {{!weightDetail.bone?'-':weightDetail.left_bone<33?"↓":weightDetail.left_bone>66.6?"↑":'✓'}}
                </el-col>
                <el-col :span="1">|</el-col>
                <el-col :span="2">08</el-col>
                <el-col :span="3">基础代谢率</el-col>
                <el-col :span="4">{{weightDetail.bmr}}千卡/日&nbsp;</el-col>
                <el-col :span="3">{{!weightDetail.bmr?'-':weightDetail.left_bone<50?"↓":'✓'}}</el-col>
            </el-row>
            <el-row class="row_detail one">
                <el-col :span="2" align="center">09</el-col>
                <el-col :span="3">蛋白质</el-col>
                <el-col :span="3">{{weightDetail.protein}}%&nbsp;</el-col>
                <el-col :span="3">
                    {{!weightDetail.protein?'-':weightDetail.left_protein<33?"↓":weightDetail.left_protein>66.6?"↑":'✓'}}
                </el-col>
                <el-col :span="1">|</el-col>
                <el-col :span="2">10</el-col>
                <el-col :span="3">肥胖等级</el-col>
                <el-col :span="4">{{level[weightDetail.level_grade]}}&nbsp;</el-col>
                <el-col :span="3">
                    {{!weightDetail.level_grade?'-':weightDetail.left_grade<16.6?"↓":weightDetail.left_grade>33.3?"↑":'✓'}}
                </el-col>
            </el-row>
            <el-row class="row_detail two">
                <el-col :span="2" align="center">11</el-col>
                <el-col :span="3">皮下脂肪率</el-col>
                <el-col :span="3">{{weightDetail.skin}}%&nbsp;</el-col>
                <el-col :span="3">
                    {{!weightDetail.skin?'-':weightDetail.left_skin<25?"↓":weightDetail.left_skin>50?"↑":'✓'}}
                </el-col>
                <el-col :span="1">|</el-col>
                <el-col :span="2">12</el-col>
                <el-col :span="3">脂肪率</el-col>
                <el-col :span="4">{{weightDetail.body}}%&nbsp;</el-col>
                <el-col :span="3">
                    {{weightDetail.body=='-'?'-':weightDetail.left_body<25?"↓":weightDetail.left_body>50?"↑":'✓'}}
                </el-col>
            </el-row>
            <el-row class="row_detail one">
                <el-col :span="2" align="center">13</el-col>
                <el-col :span="3">去脂体重</el-col>
                <el-col :span="3">{{weightDetail.free}}kg&nbsp;</el-col>
                <el-col :span="3">-</el-col>
                <el-col :span="1">|</el-col>
                <el-col :span="2">14</el-col>
                <el-col :span="3">身体类型</el-col>
                <el-col :span="4">{{type_level[weightDetail.type]}}&nbsp;</el-col>
                <el-col :span="3">-</el-col>
            </el-row>
            <el-row class="row_detail two">
                <el-col :span="2" align="center">15</el-col>
                <el-col :span="3">标准体重</el-col>
                <el-col :span="3">{{weightDetail.standard}}kg&nbsp;</el-col>
                <el-col :span="3">-</el-col>
                <el-col :span="1">|</el-col>
            </el-row>


            <div style="text-align: right;margin-right: 20px">
                <el-button @click="detailDialog = false">取 消</el-button>
                <el-button type="primary" @click="detailDialog = false">确 定</el-button>
            </div>
        </el-dialog>
        <!--用户信息编辑-->
        <el-dialog :title="userTitle" :visible.sync="userDialog" width="50%">

            <el-form ref="form" :model="user_form" label-width="80px" input-width="50px">
                <el-form-item label="病史：">
                    <el-input type="textarea"
                              :rows="6" v-model="user_form.info" minlength="3" style="width: 90%"></el-input>
                </el-form-item>

                <el-form-item>
                    <div style="text-align: right;margin-right: 20px">
                        <el-button @click="userDialog = false">取 消</el-button>
                        <el-button type="primary" @click="updateUser">确 定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>

</template>
<script>
    import request from "../../plugins/axios";

    export default {
        data() {
            return {
                deviceList: [
                    {
                        value: '1',
                        label: '血糖血压测试仪',
                        children: ['血压', '血糖']
                    },
                    {
                        value: '2',
                        label: '蓝牙通话手表',
                        children: ['心率', '血氧', '睡眠',  '运动'],//'情绪',
                    },
                    {
                        value: '3',
                        label: '健康体脂秤',
                        children: ['体脂称']
                    },
                ],
                detailDialog: false,
                weight: [],//体脂称详情
                weightDetail: [],
                loading: false,
                level: ['偏轻', '正常', '超重', '肥胖I级', '肥胖II级', '肥胖III级'],
                type_level: ['偏瘦型', '偏瘦肌肉型', '肌肉发达型', '缺乏运动型', '标准型', '标准肌肉型', '浮肿肥胖型', '偏胖肌肉型', '肌肉型偏胖'],
                deviceData: ['血压', '血糖'],
                hrole: '',
                selectKey: [],
                //用户管理
                searchForm: {
                    type: '',
                    hospital: '',
                    phone: '',
                    create_time: '',
                    device_id: ''
                },
                HospitalAllData: [],//所有社区
                RoleAllData: [],//所有角色

                userTitle: '编辑',
                user_form: {
                    curd: '',
                    mobile: '',
                    info: '',
                    id: '',
                },
                userDialog: false,
                page: 1,
                total: 0,
                pagesize: 10,
                tableData: [],


            };
        },
        components: {},
        created() {
            let that = this
            let uid = that.getQueryString('uid')
            if (uid && uid > 0) {
                that.searchForm.phone = localStorage.getItem('usphone')
                let device_name = localStorage.getItem('usdevice')
                let device_name_type = device_name.substring(0, 5)
                if (device_name_type == '健康体脂秤') {
                    that.searchForm.device_id = "3"
                } else if (device_name_type == '蓝牙通话手') {
                    that.searchForm.device_id = "2"
                } else if (device_name_type == '血糖血压测') {
                    that.searchForm.device_id = "1"
                }
            }
            let ua = JSON.parse(localStorage.getItem('ua'))
            if (ua.hospital > 0) {
                that.searchForm.hospital = ua.hospital
            }

        },
        mounted() {
            let that = this
            that.getUserData();
            that.getHospitalAllData();
            this.hrole = localStorage.getItem('hrole')
        },
        methods: {
            deviceChange(e) {
                let that = this
                let deviceList = that.deviceList
                let children = []
                deviceList.forEach(function (item, index) {
                    if (item.value == e) {
                        children = item
                    }
                })

                that.deviceData = children.children
            },
            //获取url参数
            getQueryString(name) {
                var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
                var r = window.location.search.substr(1).match(reg);
                if (r != null) {
                    return unescape(r[2]);
                }
                return null;
            },
            //用户数据
            userData() {
                window.open(this.$router.resolve({path: "/admin/data"}).href, "_blank");
            },
            //下载查询结果
            downLoad() {
                let that = this
                that.getUserData(1, '1')
            },
            //用户开始
            getUserData(page, type) {
                let that = this

                that.loading = true;
                let data = that.searchForm
                data.pagesize = that.pagesize
                data.page = page ? page : 1;
                data.download = type ? type : 0;
                data.hp = 1000
                request({
                    url: "/hospital/News/user_data",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {
                        if (type && type == 1) {
                            window.open(ret.data.save_file, "_blank").location;
                        } else {
                            that.tableData = ret.data.list
                            that.total = ret.data.total
                        }
                    } else {
                        that.$notify({
                            title: '提示',
                            message: ret.msg,
                            type: 'warning'
                        });

                    }
                    that.loading = false;
                });
            },
            //获取所有社区
            getHospitalAllData() {
                let that = this
                request({
                    url: "/hospital/News/hospital_list",
                    data: {pageSize: 1000, page: 1, hp: 1000},
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.HospitalAllData = ret.data.list


                    }
                });
            },
            //用户查询
            onSearch() {
                let that = this.getUserData();
            },
            handleSizeChange(pagesize) {
                this.pagesize = pagesize;
                this.getUserData(1);
            },
            handleCurrentChange(page) {
                this.getUserData(page);
            },
            showDetail(row) {
                let that = this
                let weight = row;
                that.weight = weight
                request({
                    url: "/hospital/News/get_weight_detail",
                    data: {id: row.id},
                }).then((ret) => {
                    if (ret.code == 1) {
                        that.weightDetail = ret.data
                    }
                    that.loading = false;
                });
                that.detailDialog = true;

            },
            editUser(row) {
                let that = this
                that.user_form.info = row.info
                that.user_form.id = row.usid;
                that.userTitle = row.user.name + '编辑'
                that.userDialog = true
            },
            updateUser() {
                let that = this
                let data = {
                    id: that.user_form.id,
                    info: that.user_form.info,
                    curd: 'u',
                }
                request({
                    url: "/hospital/News/users_update",
                    data: data,
                }).then((ret) => {
                    if (ret.code == '1') {
                        that.getUserData()
                    }
                    that.userDialog = false
                });
            },
            //删除用户
            delUser(row) {
                let that = this

                that.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: "/hospital/News/del_data",
                        data: {
                            curd: 'd',
                            id: row.id,
                            type: row.type,
                            sid: row.uid
                        },
                    }).then((ret) => {
                        if (ret.code == 1) {

                            that.$notify({
                                title: '提示',
                                message: ret.msg,
                                type: 'success'
                            });
                            that.getUserData();
                            that.roleDialog = false;
                        } else {
                            that.$notify({
                                title: '提示',
                                message: ret.msg,
                                type: 'warning'
                            });
                        }
                    });
                }).catch(() => {
                    that.$notify({
                        title: '提示',
                        message: "取消删除",
                        type: 'warning'
                    });
                });


            },
        },
    };
</script>